import { useEffect } from 'react';
import { NextRouter } from 'next/router';

const VALID_ROUTES = [
  '/how-it-works',
  '/the-snap-finance-team',
  '/partner',
  '/about-us',
  '/careers',
  '/social-responsibility',
];

export function useCustomRedirect(router: NextRouter) {
  const routePath = router.route;
  useEffect(() => {
    router.events.on('routeChangeStart', (routeUrl) => {
      if (VALID_ROUTES.includes(routeUrl)) {
        window.location.replace(
          `${process.env.NEXT_PUBLIC_COM_URL}/${routeUrl}`
        );
      }
    });
  }, [router, routePath]);
}
