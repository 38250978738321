import { Fingerprint } from './fingerprint.interface';

export interface ApplicationStatusInterface {
  NumOfPaymentsWithSameAmount: number;
  allowAchPayments: boolean;
  applicationId: number;
  applicationIdString: string;
  approvedAmount: number;
  askQuestion: boolean;
  blacklisted: boolean;
  customerState: string;
  firstPaymentDate: string;
  frequencyInformation: FrequencyInformationInterface;
  incompleteFields: boolean;
  isMerchantLender: boolean;
  lastPaymentAmount: number;
  page: ApplicationPageType;
  paymentAmount: number;
  marketingOptIn: boolean;
  requiredCardInformation: boolean;
  salesTaxApplicable: boolean;
  snapProduct: SnapProductType;
  status: ApplicationStatusType;
  termInMonths: number;
}

export enum PaymentFrequency {
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  SEMI_MONTHLY = 'SEMI_MONTHLY',
  MONTHLY = 'MONTHLY',
  MONTHLY_WEEK = 'MONTHLY_WEEK',
}

export enum PaymentFrequencyDisplay {
  W = 'week',
  BW = 'every other week',
  SM = 'twice a month',
  M = 'month',
  MW = 'month',
}

export enum IncomeTypes {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  SELF_EMPLOYMENT = 'SELF_EMPLOYMENT',
  BENEFITS = 'BENEFITS',
  OTHER = 'OTHER',
}

export interface FrequencyInformationInterface {
  frequency: PaymentFrequency;
  frequencyText: string;
}

export type ApplicationPageType =
  | 'PERSONAL_INFO'
  | 'BANK'
  | 'INCOME'
  | 'VERIFY_INFORMATION'
  | 'IDOLOGY'
  | 'YODLEE'
  | 'DENIED'
  | 'VERIFY_ADDRESS'
  | 'APPROVED_WITH_CONDITIONS';

export type ApplicationStatusType =
  | 'APPROVED'
  | 'DENIED'
  | 'PENDING_DOCS'
  | 'PENDING'
  | 'PENDING_COMPLETION'
  | 'APPROVED_WITH_CONDITIONS'
  | 'PENDING_DEL'
  | 'PENDING_SETTL'
  | 'COMPLETE'
  | 'ERROR'
  | 'INACTIVE'
  | 'FUNDED';

export type SnapProductType = 'RTO' | 'SSL' | 'SLN' | 'LTO';

export type ApplicationSource =
  | 'SNAP_FINANCE_COM'
  | 'MERCHANT'
  | 'INTERNET'
  | 'REMARKET'
  | 'KIOSK'
  | 'SMS'
  | 'STORIS'
  | 'ECOMM_BANNER'
  | 'SHORT_CODE'
  | 'ECOMM_XO';

export type PlatformModel = 'FA' | 'PQA';

export type UIExperience = 'CHECKOUT' | 'PRE_APPROVAL' | 'PRE_QUALIFICATION';

export type MarketingTreatmentType =
  | 'PRE_APPROVAL'
  | 'PRE_QUALIFICATION'
  | 'AS_LOW_AS';

export type AgreementType = 'LTO' | 'RIC' | 'TAB' | 'BP1';

export interface merchantIndustry {
  code: string;
  displayText: string;
}

export type ApplicationType = SnapProductType;

export interface LaunchAppConfig {
  kona: boolean;
  source: ApplicationSource;
  testVariation: string;
  merchantId: string;
  externalMerchantId: string;
  applyPath?: {
    type: ApplicationType;
    merchant_id: string;
    path: string;
  } | null;
  paramId: string;
  platformModel?: PlatformModel;
  rakutenParams?: unknown;
  productType?: SnapProductType;
}
