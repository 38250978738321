import { Frequency } from '@ee-monorepo/shared/utilities/types';

export interface ButtonItem {
  value: Frequency;
  frequency: number;
}

export const buttonItems: ButtonItem[] = [
  { value: Frequency.W, frequency: 78 },
  { value: Frequency.SM, frequency: 36 },
  { value: Frequency.BW, frequency: 39 },
  { value: Frequency.M, frequency: 18 },
];

export const sliderAmounts = ['$150', '$1,250', '$2,500', '$3,750', '$5,000'];
