import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { useGet } from '@ee-monorepo/shared/utilities/hooks';

interface Question {
  value: string;
}

export function useSurveyQuestions(): { questions: Question[] } {
  const { data } = useGet<Question[]>(
    `${appConfig.merchantOnboardingEndpoint}/commons/merchant-survey-questions`
  );
  return { questions: data || [] };
}
