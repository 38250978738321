import { useTranslations } from 'next-intl';
import { Button } from '@ee-monorepo/shared/ui/button';
import Image from 'next/image';
import { useEffect, useRef } from 'react';

/* eslint-disable-next-line */
export interface SharedUiErrorViewProps {
  retryMethod?: () => void;
}

export function SharedUiErrorView({ retryMethod }: SharedUiErrorViewProps) {
  const t = useTranslations();
  const retry = () => {
    retryMethod?.();
  };
  const errrorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    errrorRef?.current?.parentElement?.parentElement?.scrollIntoView?.({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }, []);

  return (
    <div
      ref={errrorRef}
      className="mb-2 p-2 z-10 md:z-0 text-[16px] w-full flex flex-col items-center bg-SnapDarkBlue text-SnapWhite"
    >
      <Image
        src={'assets/icons/store-locator/blue-electric-plug.svg'}
        alt="api-error"
        width={160}
        height={161}
      />
      <h3 className="p-2">{t('shared.store_locator_failure.bold_text')}</h3>
      <h6 className="p-2 sm:p-0 mb-2">
        {t('shared.store_locator_failure.label')}
      </h6>
      <Button
        variant="secondary"
        className="mb-5 p-x-4 p-y-3 border-2 border-solid text-SnapWhite border-SnapWhite"
        onClick={retry}
      >
        {t('shared.store_locator_failure.cta_text')}
      </Button>
    </div>
  );
}

export default SharedUiErrorView;
