import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { useFetchWithErrorTracking } from '@ee-monorepo/shared/utilities/hooks';

export function useFetchAddress() {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();

  const fetchAddress = async ({ global_address_key }) => {
    const response = await fetchWithErrorTracking(
      appConfig.addressValidationApi + global_address_key,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const result = await response.json();

    if (response.status !== 200) {
      return null;
    } else {
      return result;
    }
  };
  return { fetchAddress };
}
