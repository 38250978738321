import { useEffect, useRef, useState } from 'react';
import {
  getSessionInfo,
  trackMerchantLeadCreated,
} from '@ee-monorepo/shared/utilities/functions';
import {
  MerchantLongFormInterface,
  CreateMerchantLeadProps,
  CreateMerchantLeadData,
} from '@ee-monorepo/shared/utilities/types';
import {
  useCall,
  useFetchWithErrorTracking,
} from '@ee-monorepo/shared/utilities/hooks';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { LeadSourceType } from '@ee-monorepo/tracking';

export const useMerchantLeadAPI = (
  t: (arg: string) => string,
  onSuccess: () => void,
  onFailure: () => void
) => {
  const { tracking } = useTrackingContext();
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();
  const [payload, setPayload] = useState(null);
  const onSucessRef = useRef(null);
  onSucessRef.current = onSuccess;
  const onFailureRef = useRef(null);
  onFailureRef.current = onFailure;

  const { call, loading, data, error } = useCall<CreateMerchantLeadData>(
    async (props: CreateMerchantLeadProps) => {
      const sessionInfo = getSessionInfo();

      let payloadData: unknown;

      if (props.formName === 'LONG_FORM') {
        const data = setupLongFormPayload(
          props.data as MerchantLongFormInterface
        );
        payloadData = {
          formName: props.formName,
          ...data,
          sessionInfo,
        };
      } else {
        payloadData = { formName: props.formName, ...props.data, sessionInfo };
      }

      setPayload(payloadData);

      const URL = process.env['NEXT_PUBLIC_MERCHANT_LEADS_URL'] || '';
      return fetchWithErrorTracking(URL, {
        method: 'POST',
        body: JSON.stringify(payloadData),
      });
    }
  );

  useEffect(() => {
    if (!data || loading) {
      return;
    }

    if (data?.success) {
      tracking.logMerchantLeadEvent({
        lead_source: t(
          `merchant_lead_source.${payload.formName}`
        ) as LeadSourceType,
        lead_id: (data as CreateMerchantLeadData).data?.id as string,
        business_name: payload?.businessName,
      });
      trackMerchantLeadCreated({
        lead_source: t(
          `merchant_lead_source.${payload.formName}`
        ) as LeadSourceType,
        business_name: payload?.businessName,
        lead_id: (data as CreateMerchantLeadData).data?.id as string,
      });
      onSucessRef.current();
    } else {
      tracking.logContentViewedEvent({
        content_name: t(`merchant_lead_error.${payload.formName}`),
        content_value: t('formSubmissionFailed').toLowerCase(),
      });
      onFailureRef.current();
    }
  }, [data, onSucessRef, onFailureRef, tracking, t, loading]);

  return {
    createMerchantLead: call,
    createMerchantLeadLoading: loading,
  };
};

export const setupLongFormPayload = (data: MerchantLongFormInterface) => {
  const {
    industry,
    industrySubCategory,
    industrySubCategoryOther,
    offerLocation,
    numberOfLocations,
    website,
    ecommPlatform,
    ecommPlatformOther,
    referralSource,
  } = data.ProductServiceData;

  const { revenue, ein } = data.FinancialData;

  const { manualDataEntered, ...businessData } = data.BusinessData;

  return {
    ...businessData,
    ...{
      industry,
      industrySubCategory: industry === 'OTHER' ? 'OTHER' : industrySubCategory,
      industrySubCategoryOther:
        industrySubCategory === 'OTHER' && industry !== 'OTHER'
          ? 'OTHER'
          : industrySubCategoryOther,
      offerLocation,
      numberOfLocations,
      ecommPlatform,
      ecommPlatformOther,
      website,
      revenue,
      ein,
      referralSource,
    },
  };
};
