import { LocationType } from './location.type';
import {
  CoordinateInterface,
  MerchantInterface,
  BusinessReviewInterface,
  BusinessDetailInterface,
} from './merchant.interface';

export interface ApiResponse {
  success: boolean;
  lead_id?: string;
}

export interface MerchantSearchResponse {
  merchants: Merchant[];
  totalResults: number;
}

export interface IndustrySearchResponse {
  industries: MerchantIndustry[];
}

export type Merchant = MerchantInterface;

export type MerchantBusinessReview = BusinessReviewInterface;
export type MerchantBusinessDetail = BusinessDetailInterface;
export interface MerchantIndustry {
  name: string;
  industry: Industry;
}

export type Location = LocationType;

export type Industry =
  | 'ALL'
  | 'APPLIANCES'
  | 'AUTO_SERVICE'
  | 'CAR_AUDIO'
  | 'CELL_PHONES'
  | 'COLLISION_REPAIR'
  | 'DENTAL'
  | 'ELECTRONICS'
  | 'FURNITURE'
  | 'HOME_AUTO_ALARMS'
  | 'HOME_DECOR'
  | 'HVAC'
  | 'JEWELRY'
  | 'LAWN_AND_GARDEN'
  | 'MATTRESSES'
  | 'MEDICAL_DEVICES'
  | 'MUSICAL_INSTRUMENTS'
  | 'OTHER'
  | 'POWER_TOOLS'
  | 'SCOOTERS'
  | 'SPORTING_GOODS'
  | 'TIRES_RIMS'
  | 'COLLISION_REPAIR';

export interface MerchantsQuery {
  zipCode?: string;
  userCoordinates?: CoordinateInterface;
  distance?: number;
  includeKona?: boolean;
  offset?: number;
  limit?: number;
  industry?: string;
  keyWord?: string;
  pageNumber?: string;
}
export interface IndustriesQuery {
  zipCode?: string;
  userCoordinates?: CoordinateInterface;
  distance?: number;
  includeKona?: boolean;
}
export interface OnlineMerchantsQuery {
  offset: number;
  limit: number;
  industry?: string;
  keyWord?: string;
}
export interface PaymentCalculatorQuery {
  items: PaymentCalculatorItems[];
}

export enum Frequency {
  'BW' = 'BW',
  'W' = 'W',
  'SM' = 'SM',
  'M' = 'M',
  'MW' = 'MW',
}

export interface PaymentCalculatorItems {
  price: number;
  itemId?: string;
}

export interface PaymentCalculatorResponse {
  success: boolean;
  data: PaymentCalculatorResponseData;
  status: number;
}

export interface PaymentCalculatorResponseData {
  paymentScheduleAmount: number;
  totalCost: number;
  costOfLease: number;
}

export interface StateDetails {
  cities: [
    {
      zipCode: string;
      name: string;
      abbreviation: string;
    }
  ];
  start: number;
  totalPages: number;
  pageSize: number;
  state: {
    name: string;
    abbreviation: string;
  };
  totalCities: number;
}

export interface CreateMerchantLeadData{
  data?: {
    id: string;
    message: string;
  },
  status?: number;
  success: boolean;
}