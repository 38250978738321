import { useCallback, useState } from 'react';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import {
  PaymentCalculatorResponseData,
  PaymentCalculatorQuery,
  Frequency,
} from '@ee-monorepo/shared/utilities/types';
import { useFetchWithErrorTracking } from '@ee-monorepo/shared/utilities/hooks';

const DEFAULT_QUERY: PaymentCalculatorQuery = {
  items: [{ price: 1250, itemId: 'ABC' }],
};

const usePaymentCalculatorAPI = () => {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();
  const [loading, setLoading] = useState(false);
  const [ErrorLoading, setErrorLoading] = useState(false);
  const fetchPayment = useCallback(
    async (
      frequency: Frequency,
      query: PaymentCalculatorQuery
    ): Promise<PaymentCalculatorResponseData> => {
      setLoading(true);
      try {
        const response = await fetchWithErrorTracking(
          `${appConfig.paymentCalculatorEndpoint}?frequency=${frequency}&term18=true`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'referrer-policy': 'no-referrer-when-downgrade',
            },
            body: JSON.stringify({ ...DEFAULT_QUERY, ...query }),
          }
        );
        const json = await response.json();
        setLoading(false);
        if (response.status !== 200 || json.error) {
          setErrorLoading(true);
          return null;
        } else {
          setErrorLoading(false);

          const { paymentScheduleAmount } = json.data.items[0];
          const { totalCost, costOfLease } = json.data;
          return {
            paymentScheduleAmount,
            totalCost,
            costOfLease,
          };
        }
      } catch {
        setLoading(false);
        setErrorLoading(true);
        return null;
      }
    },
    [fetchWithErrorTracking]
  );
  return {
    fetchPayment,
    loading,
    ErrorLoading,
  };
};

export { usePaymentCalculatorAPI };
