import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { Box, Card, CardContent, Typography, Input } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FrequencyButton, { Button } from './frequency-button';
import cn from 'classnames';
import { Slice } from '@ee-monorepo/prismic/shared/types';
import {
  ButtonContainer,
  IconButtonStyled,
  MiddleCalculator,
  MobileDisclaimer,
  SliderAndButtonContainer,
} from './styles';
import { useEffect, useRef } from 'react';
import {
  getColor,
  currencyFormatter,
} from '@ee-monorepo/shared/utilities/functions';
import { PaymentCalculatorResponseData } from '@ee-monorepo/shared/utilities/types';
import { buttonItems, ButtonItem } from './constants';
import PaymentInfo from './payment-info';
import Disclaimer from './disclaimer';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { PrismicText } from '@prismicio/react';
import { SharedUiErrorView } from '@ee-monorepo/shared/ui/error-view';
import { AppSpinner } from '@one-snap/sparkles';

interface PaymentCalculatorSlice extends Slice {
  primary: Primary;
}

export interface PaymentCalculatorProps extends PrismicFields.SliceBase {
  slice: PaymentCalculatorSlice;
  data: PaymentCalculatorResponseData;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  selected: ButtonItem;
  setSelected: React.Dispatch<React.SetStateAction<ButtonItem>>;
  loading?: boolean;
  errorLoading?: boolean;
  callGetPayments?: () => void;
}

interface Primary {
  header: PrismicFields.RichTextField;
  subheader: PrismicFields.RichTextField;
  bg_color: PrismicFields.Select;
  header_color: PrismicFields.Select;
  subheader_color: PrismicFields.Select;
}

export function PaymentCalculatorMobile(props: PaymentCalculatorProps) {
  const translate = useTranslations();
  const {
    data,
    setValue,
    slice,
    value,
    selected,
    setSelected,
    loading,
    errorLoading,
    callGetPayments,
  } = props;
  const inputRef = useRef(null);
  const { primary } = slice;
  const [active, setActive] = useState(false);
  const activeClass = active
    ? 'bodyHeavyFont text-SnapGreen self-center'
    : 'absolute opacity-0';

  const handleSubtractClick = () => {
    const newValue = value - 25;
    setValue(Math.max(150, newValue));
  };

  const handleAddClick = () => {
    const newValue = value + 25;
    setValue(Math.min(5000, newValue));
  };

  const handleClick = (item) => {
    setSelected(item);
  };

  const handleToggle = () => {
    setActive(!active);
  };

  const [mobileLoading, setMobileLoading] = useState(loading);
  const [mobileErrorLoading, setMobileErrorLoading] = useState(errorLoading);

  useEffect(() => {
    setMobileLoading(loading);
    setMobileErrorLoading(errorLoading);
  }, [loading, errorLoading]);

  return (
    <div>
      {/* Keeping it for placing loader in future */}
      {/* {mobileLoading ? (
        <div>
          <AppSpinner />
        </div>
      ) : null} */}

      <Box
        data-testid="paymentCalculator"
        data-slice-index={props.index}
        className="md:px-8 bg-SnapDarkBlue w-full flex flex-col items-center"
      >
        <Box className="flex flex-col items-center my-10 px-4">
          <Typography
            className={cn(
              'text-SnapLightBlue',
              getColor(primary?.header_color).textColorClass,
              'headingL',
              'mb-2'
            )}
          >
            <PrismicText field={primary?.header} />
          </Typography>
          <Typography
            className={cn(
              'text-SnapWhite',
              'bodyFont',
              getColor(primary?.subheader_color).textColorClass,
              'font-normal'
            )}
          >
            <PrismicText field={primary?.subheader} />
          </Typography>
        </Box>
        {mobileErrorLoading ? (
          <SharedUiErrorView retryMethod={callGetPayments} />
        ) : (
          <>
            <Card data-testid="PaymentCalculator" className={cn('w-full')}>
              <CardContent
                data-testid="PaymentCalculator2"
                className={cn('py-5', 'flex', 'snap-container', 'flex-col')}
              >
                <SliderAndButtonContainer className={cn('lg:pr-12')}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className="font-bold pb-2 uppercase"
                    >
                      {translate(
                        'shared.payment_calculator.select_transaction_amount'
                      )}
                    </Typography>
                  </Box>
                  <MiddleCalculator
                    className={cn(
                      'p-2',
                      'flex',
                      'justify-between',
                      'items-center'
                    )}
                  >
                    <IconButtonStyled
                      color="primary"
                      onClick={handleSubtractClick}
                      data-testid="subtractButton"
                    >
                      <RemoveIcon />
                    </IconButtonStyled>
                    <Button
                      onClick={() => {
                        inputRef.current.focus();
                        handleToggle();
                      }}
                      disableRipple
                    >
                      {active && (
                        <span className="bodyHeavyFont text-SnapGreen self-center">
                          $
                        </span>
                      )}
                      <Input
                        type="number"
                        ref={inputRef}
                        inputProps={{
                          pattern: '[0-9]*',
                        }}
                        className={cn(activeClass)}
                        value={value}
                        onChange={(event) => {
                          const val = Number(event?.target?.value);
                          setValue(val);
                        }}
                      />
                      {!active && (
                        <Typography className="bodyHeavyFont text-SnapGreen self-center">
                          {currencyFormatter.format(value)}
                        </Typography>
                      )}
                    </Button>
                    <IconButtonStyled
                      color="primary"
                      onClick={handleAddClick}
                      data-testid="addButton"
                    >
                      <AddIcon />
                    </IconButtonStyled>
                  </MiddleCalculator>
                  <Typography
                    variant="subtitle2"
                    className="mt-2 mb-5 text-center text-SnapGrey400"
                  >
                    <span className={cn(value < 150 && 'text-SnapError')}>
                      {translate('shared.min')}. $150
                    </span>{' '}
                    /{' '}
                    <span className={cn(value > 5000 && 'text-SnapError')}>
                      {translate('shared.max')}. $5,000
                    </span>
                  </Typography>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className="font-bold pb-2 uppercase"
                    >
                      {translate('shared.payment_calculator.frequency_of_pay')}
                    </Typography>
                    <ButtonContainer className={cn('mb-5', 'flex-wrap')}>
                      {buttonItems.map((item) => (
                        <FrequencyButton
                          {...item}
                          key={item?.value}
                          onClick={() => handleClick(item)}
                          selected={selected?.value === item.value}
                          label={translate(
                            `shared.payment_calculator.frequency_buttons.${item?.value}`
                          )}
                        />
                      ))}
                    </ButtonContainer>
                  </Box>
                </SliderAndButtonContainer>
                <PaymentInfo data={data} selected={selected} isTablet />
              </CardContent>
            </Card>
            <MobileDisclaimer className={cn('mx-4', 'my-8')}>
              <Disclaimer data={data} selected={selected} />
            </MobileDisclaimer>
          </>
        )}
      </Box>
    </div>
  );
}

export default PaymentCalculatorMobile;
