import { useEffect } from 'react';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { useCall } from '@ee-monorepo/shared/utilities/hooks';
import { useFetchWithErrorTracking } from '@ee-monorepo/shared/utilities/hooks';

interface Industry {
  id: number;
  displayText: string;
  name: string;
}

export function useIndustriesByState(currentState: string) {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();
  const { call, loading, data, error } = useCall<Industry[]>((state: string) =>
    fetchWithErrorTracking(`${appConfig.productMS}/v1/industry?state=${state}`)
  );

  useEffect(() => {
    if (currentState) {
      call(currentState);
    }
  }, [currentState, call]);

  return {
    loading,
    industries: data || [],
    error,
  };
}
