import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { useGet } from '@ee-monorepo/shared/utilities/hooks';

export interface State {
  id: number;
  abbreviation: string;
  name: string;
}

export function useStates(): { states: State[] } {
  const { data } = useGet<State[]>(`${appConfig.productMS}/v1/state`);
  return { states: data || [] };
}
