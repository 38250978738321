import MuiButton from '@mui/material/Button';
import { colors } from '@snap/colors';
import { styled } from '@mui/material/styles';
import cn from 'classnames';

export const buildColor = (
  theme,
  getContrast = false,
  backupColor = colors.SnapGreen
) => ({
  borderColor: (props) =>
    getContrast
      ? theme.palette.getContrastText(
          theme.palette[props.color]?.main ?? backupColor
        )
      : theme.palette[props.color]?.main ?? backupColor,
  color: (props) =>
    getContrast
      ? theme.palette.getContrastText(
          theme.palette[props.color]?.main ?? backupColor
        )
      : theme.palette[props.color]?.main ?? backupColor,
});

export const Button = styled(MuiButton)(({ theme }) => ({
  borderRadius: 6,
  boxShadow: 'none',
  fontSize: 16,
  height: 48,
  padding: '10px 22px',
  fontWeight: 400,
  textTransform: 'inherit',
  borderColor: colors.SnapGrey400,
  color: colors.SnapGrey400,
  whiteSpace: 'nowrap',

  lineHeight: 1.2,
  [theme.breakpoints.up('xl')]: {
    width: 175,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    padding: 10,
  },
  [theme.breakpoints.down('sm')]: {
    width: 165,
    padding: 0,
  },

  '&:hover': {
    boxShadow: 'none',
    borderWidth: 2,
    borderColor: colors.SnapGreen,
    color: colors.SnapGreen,
    backgroundColor: 'initial',
    fontWeight: 700,
  },
  '&.MuiButton-sizeSmall': {
    borderRadius: 80,
    fontSize: 14,
    padding: '8px 22px',
    height: 40,
  },
  '&.MuiButton-containedPrimary:hover': {
    backgroundColor: colors.SnapGreen,
  },
  '&.MuiButton-containedPrimary': {
    ...buildColor(theme, true),
    '&.selected': {
      ...buildColor(theme, true),
    },
  },
  '&.selected': {
    ...buildColor(theme, false, colors.SnapGreen),
    borderColor: colors.SnapGreen,
    color: colors.SnapGreen,
    borderWidth: 2,
    fontWeight: 700,
  },
}));

interface FrequencyButtonProps {
  'data-testid'?: string;
  selected: boolean;
  onClick: () => void;
  className?: string;
  label: string | React.ReactNodeArray | React.ReactElement;
}

const FrequencyButton = ({
  label,
  'data-testid': dataTestId,
  selected,
  onClick,
  className,
}: FrequencyButtonProps) => (
  <Button
    className={cn(selected && 'selected', className)}
    variant="outlined"
    color="secondary"
    data-testid={dataTestId}
    onClick={onClick}
  >
    {label}
  </Button>
);

export default FrequencyButton;
