import { Box, IconButton, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@snap/colors';

export const AmountText = styled(Box)(({ theme }) => ({
  fontWeight: 700,
  fontSize: theme.spacing(9),
  color: colors.SnapBlue,
  lineHeight: '84px',
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.spacing(4),
    lineHeight: '40px',
  },
}));

export const PaymentDisplayFrequencyText = styled(Typography)(({ theme }) => ({
  lineHeight: '16px',
  color: colors.SnapGrey500,
  [theme.breakpoints.down('sm')]: {
    color: colors.SnapGrey400,
    fontSize: 10,
  },
}));

export const NumberOfPayments = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.spacing(3),
    lineHeight: '36px',
  },
}));

export const SliderStyles = styled(Box)(({ theme }) => ({
  backgroundColor: colors.SnapGrey200,
  border: `1px solid ${colors.SnapGrey300}`,
  height: 56,
  width: '100%',
  borderRadius: '6px 6px 0px 0px',
  display: 'flex',
}));

export const SliderAmountStyles = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: colors.SnapGreen,
  lineHeight: '40px',
  alignSelf: 'center',
  marginLeft: theme.spacing(2),
}));

export const SliderSectionStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(6),
}));

export const SliderAmountPosition = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    rowGap: 12,
  },
  [theme.breakpoints.down('md')]: {
    columnGap: 12,
  },
}));

export const DisclaimerHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const WebDisclaimer = styled('div')(({ theme }) => ({
  color: colors.SnapGrey400,
}));

export const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .Mui-focusVisible': {
    boxShadow: 'none !important',
  },
  '& .MuiSlider-thumb': {
    border: `2px solid ${theme.palette.common.white}`,
    height: 16,
    width: 16,
    marginLeft: -theme.spacing(1),
    '&:hover': {
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-rail': {
    backgroundColor: colors.SnapGrey300,
    height: 4,
    opacity: 1,
  },
  '& .MuiSlider-track': {
    height: 4,
  },
  '&': {
    paddingTop: 0,
    marginTop: -8,
  },
}));

export const SliderAndButtonContainer = styled(Box)(({ theme }) => ({
  flex: 6,
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(3),
  },
}));

export const PaymentDisplayContainer = styled(Box)(({ theme }) => ({
  flex: 3,
}));

export const MobileDisclaimer = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const MobilePaymentDisplayHeader = styled(Typography)(({ theme }) => ({
  fontSize: 14,
}));

export const MiddleCalculator = styled(Box)(({ theme }) => ({
  backgroundColor: colors.SnapGrey100,
  borderRadius: 6,
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  border: '2px solid',
  height: theme.spacing(4),
  width: theme.spacing(4),
}));
