import { Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import { currencyFormatter } from '@ee-monorepo/shared/utilities/functions';
import { PaymentCalculatorResponseData } from '@ee-monorepo/shared/utilities/types';
import { ButtonItem } from './constants';
import { DisclaimerHeader } from './styles';

export interface DisclaimerProps {
  data: PaymentCalculatorResponseData;
  selected: ButtonItem;
}

export const Disclaimer = ({ data, selected }: DisclaimerProps) => {
  const translate = useTranslations();

  return (
    <>
      <DisclaimerHeader variant="body2">
        {translate('shared.payment_calculator.illustrative_purposes')}
      </DisclaimerHeader>
      <Typography variant="body2" data-testid="disclaimerText">
        {translate('shared.payment_calculator.disclaimer_example', {
          frequency: selected?.frequency,
          frequencyText: translate(
            `shared.payment_calculator.frequency.${selected?.value}`
          ) as string,
          totalCost: currencyFormatter.format(data?.totalCost),
          costOfLease: currencyFormatter.format(data?.costOfLease),
        })}
      </Typography>
    </>
  );
};

export default Disclaimer;
