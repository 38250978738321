import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { Box, Card, CardContent, Typography } from '@mui/material';
import FrequencyButton from './frequency-button';
import cn from 'classnames';
import { Slice } from '@ee-monorepo/prismic/shared/types';
import { SliceWrapper } from '@ee-monorepo/prismic/shared/ui';
import {
  useHandHeld,
  useTablet,
  useDebounce,
} from '@ee-monorepo/shared/utilities/hooks';
import { usePaymentCalculatorAPI } from '@ee-monorepo/shared/data-access/services';
import {
  ButtonContainer,
  SliderAmountPosition,
  SliderAmountStyles,
  SliderAndButtonContainer,
  SliderSectionStyles,
  SliderStyles,
  StyledSlider,
  WebDisclaimer,
} from './styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  getColor,
  currencyFormatter,
} from '@ee-monorepo/shared/utilities/functions';
import {
  PaymentCalculatorResponseData,
  Frequency,
} from '@ee-monorepo/shared/utilities/types';
import { buttonItems, sliderAmounts } from './constants';
import PaymentCalculatorMobile from './payment-calculator-mobile';
import PaymentInfo from './payment-info';
import Disclaimer from './disclaimer';
import { useTranslations } from 'next-intl';
import { PrismicRichText } from '@prismicio/react';
import { SharedUiErrorView } from '@ee-monorepo/shared/ui/error-view';
interface PaymentCalculatorSlice extends Slice {
  primary: Primary;
}

export interface PaymentCalculatorProps extends PrismicFields.SliceBase {
  slice: PaymentCalculatorSlice;
}

interface Primary {
  header: PrismicFields.RichTextField;
  subheader: PrismicFields.RichTextField;
  bg_color: PrismicFields.Select;
  header_color: PrismicFields.Select;
  subheader_color: PrismicFields.Select;
}

const defaultValue = 1250;
const defaultData = {
  paymentScheduleAmount: 74.35,
  totalCost: 2487.5,
  costOfLease: 2686.5,
};

export function PaymentCalculator(props: PaymentCalculatorProps) {
  const t = useTranslations();
  const isMobile = useHandHeld();
  const isTablet = useTablet();
  const [value, setValue] = useState(defaultValue);
  const valueDebounced = useDebounce(value, 800);
  const [data, setData] = useState(
    defaultData as PaymentCalculatorResponseData
  );
  const [selected, setSelected] = useState(buttonItems[1]); // defaults to Twice a Month
  const selectedDebounced = useDebounce(selected, 800);

  const { primary } = props.slice;

  const { fetchPayment, loading, ErrorLoading } = usePaymentCalculatorAPI();

  const getPayments = async () => {
    const response = await fetchPayment(Frequency.SM, {
      items: [{ price: defaultValue, itemId: 'ABC' }],
    });
    if (response !== null) {
      setData(response);
    }
  };

  const getPaymentsDebounce = async (val) => {
    const response = await fetchPayment(selectedDebounced.value, {
      items: [{ price: val, itemId: 'ABC' }],
    });
    if (response !== null) {
      setData(response);
    }
  };

  const callGetPayments = useCallback(() => {
    if (valueDebounced && valueDebounced >= 150 && valueDebounced <= 5000) {
      getPaymentsDebounce(valueDebounced);
    } else {
      getPayments();
    }
  }, []);

  useEffect(() => {
    getPayments();
  }, [fetchPayment]);

  useEffect(() => {
    if (valueDebounced && valueDebounced >= 150 && valueDebounced <= 5000) {
      getPaymentsDebounce(valueDebounced);
    }
  }, [fetchPayment, selectedDebounced, valueDebounced]);

  const handleSliderChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleClick = (item) => {
    if (item.value !== selected?.value) {
      setSelected(item);
    }
  };

  if (isMobile) {
    return (
      <PaymentCalculatorMobile
        {...props}
        value={value}
        setValue={setValue}
        data={data}
        selected={selected}
        setSelected={setSelected}
        loading={loading}
        errorLoading={ErrorLoading}
        callGetPayments={callGetPayments}
      />
    );
  }

  return (
    <SliceWrapper
      className={cn(
        'md:px-8 bg-SnapDarkBlue w-full h-full md:flex flex-col items-center mt-0 sm:hidden',
        {
          'pb-4 pt-0': ErrorLoading,
        }
      )}
      data-testid="paymentCalculator"
      data-slice-index={props.index}
    >
      {/* Keeping it for placing loader in future */}
      {/* {loading ? <div><SpinnerRing /></div> : null} */}

      <div>
        <Box className="flex flex-col items-center my-[4rem] snap-container-no-pad text-center">
          <Box
            className={cn(
              'text-SnapLightBlue',
              getColor(primary?.header_color).textColorClass
            )}
          >
            <PrismicRichText field={primary?.header} />
          </Box>
          <Box
            className={cn(
              'text-SnapWhite [&>h3]:font-normal',
              getColor(primary?.subheader_color).textColorClass
            )}
          >
            <PrismicRichText field={primary?.subheader} />
          </Box>
        </Box>
        {ErrorLoading ? (
          <SharedUiErrorView retryMethod={callGetPayments} />
        ) : (
          <Card data-testid="PaymentCalculator" className={cn('mb-[4rem]')}>
            <CardContent
              data-testid="PaymentCalculator2"
              className="p-12 flex snap-container lg:flex-row md:flex-col md:px-12"
            >
              <SliderAndButtonContainer className={cn('lg:pr-12')}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    className="font-bold pb-2 uppercase"
                  >
                    {t('shared.payment_calculator.select_transaction_amount')}
                  </Typography>
                </Box>
                <Box>
                  <SliderSectionStyles>
                    <SliderStyles>
                      <SliderAmountStyles>
                        {currencyFormatter.format(value)}
                      </SliderAmountStyles>
                    </SliderStyles>
                    <StyledSlider
                      data-testid="paymentCalculatorSlider"
                      value={value}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                      step={25}
                      min={150}
                      max={5000}
                    />
                    <SliderAmountPosition>
                      {sliderAmounts.map((sliderAmount) => (
                        <Typography key={sliderAmount} variant="body2">
                          {sliderAmount}
                        </Typography>
                      ))}
                    </SliderAmountPosition>
                    <Typography variant="body2">
                      {t('shared.payment_calculator.adjust_the_slider')}
                    </Typography>
                  </SliderSectionStyles>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    className="font-bold pb-2 uppercase"
                  >
                    {t('shared.payment_calculator.frequency_of_pay')}
                  </Typography>
                  <ButtonContainer className={cn('mb-4')}>
                    {buttonItems.map((item) => (
                      <FrequencyButton
                        {...item}
                        key={item.value}
                        onClick={() => handleClick(item)}
                        selected={selected?.value === item?.value}
                        data-testid={`paymentCalculatorFrequencyButton${item?.value}`}
                        label={t(
                          `shared.payment_calculator.frequency_buttons.${item?.value}`
                        )}
                      />
                    ))}
                  </ButtonContainer>
                  <WebDisclaimer>
                    <Disclaimer data={data} selected={selected} />
                  </WebDisclaimer>
                </Box>
              </SliderAndButtonContainer>
              <div>
                <PaymentInfo
                  data={data}
                  selected={selected}
                  isTablet={isTablet}
                />
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </SliceWrapper>
  );
}

export default PaymentCalculator;
