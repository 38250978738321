import { Box, Divider, Typography } from '@mui/material';
import cn from 'classnames';
import {
  AmountText,
  MobilePaymentDisplayHeader,
  NumberOfPayments,
  PaymentDisplayContainer,
  PaymentDisplayFrequencyText,
} from './styles';
import { currencyFormatter } from '@ee-monorepo/shared/utilities/functions';
import { PaymentCalculatorResponseData } from '@ee-monorepo/shared/utilities/types';
import { useTranslations } from 'next-intl';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { ApplyButton } from '@ee-monorepo/shared/ui/apply-button';

export interface PaymentInfoProps {
  selected: {
    value: string;
    frequency: number;
  };
  data: PaymentCalculatorResponseData;
  isTablet?: boolean;
}

export interface PaymentInfoMobileProps extends PaymentInfoProps {
  payments: string;
  translate: (string) => string | React.ReactNodeArray | React.ReactElement;
}

const PaymentInfoMobile = ({
  selected,
  data,
  payments,
  translate,
}: PaymentInfoMobileProps) => {
  const { tracking } = useTrackingContext();
  return (
    <>
      <Divider />
      <PaymentDisplayContainer>
        <Box className={cn('flex', 'justify-between', 'items-center', 'my-4')}>
          <MobilePaymentDisplayHeader
            variant="subtitle2"
            className="font-bold pb-2 uppercase"
          >
            {translate('shared.payment_calculator.payment_plan')}
          </MobilePaymentDisplayHeader>
          <Box>
            <AmountText>{payments}</AmountText>
            <PaymentDisplayFrequencyText
              variant="subtitle2"
              className="font-bold pb-2 uppercase"
            >
              {translate(
                `shared.payment_calculator.frequency_buttons.${selected?.value}`
              )}
            </PaymentDisplayFrequencyText>
          </Box>
        </Box>
        <Divider />
        <Box className={cn('flex', 'justify-between', 'items-center', 'my-4')}>
          <Typography variant="subtitle2" className="font-bold pb-2 uppercase">
            {translate('shared.payment_calculator.number_of_payments')}
          </Typography>
          <NumberOfPayments className={cn('headingL', 'text-SnapBlue')}>
            {selected?.frequency}
          </NumberOfPayments>
        </Box>
        <Divider />
        <Box className={cn('flex', 'justify-between', 'items-center', 'my-4')}>
          <Typography variant="subtitle2" className="font-bold pb-2 uppercase">
            {translate('shared.payment_calculator.total_payments')}
          </Typography>
          <NumberOfPayments className={cn('headingL', 'text-SnapBlue')}>
            {currencyFormatter.format(data?.totalCost)}
          </NumberOfPayments>
        </Box>
        <ApplyButton
          data-testid="apply-btn"
          className={cn('w-full', 'mt-2')}
          onClick={() => {
            tracking.logClickEvent({
              click_type: 'cta',
              click_value: translate('shared.apply_now') as string,
              placement: 'calculator',
            });
          }}
        >
          {translate('shared.apply_now')}
        </ApplyButton>
      </PaymentDisplayContainer>
    </>
  );
};

export function PaymentInfo(props: PaymentInfoProps) {
  const translate = useTranslations();
  const { selected, data, isTablet } = props;
  const payments = currencyFormatter.format(data?.paymentScheduleAmount);
  const { tracking } = useTrackingContext();
  if (isTablet) {
    return (
      <PaymentInfoMobile {...props} payments={payments} translate={translate} />
    );
  }

  return (
    <PaymentDisplayContainer>
      <Typography
        variant="subtitle2"
        className="font-bold pb-2 uppercase leading-4"
      >
        {translate('shared.payment_calculator.payment_plan')}
      </Typography>
      <AmountText>{payments}</AmountText>
      <PaymentDisplayFrequencyText
        variant="subtitle2"
        className="font-bold pb-8 uppercase"
      >
        {translate(
          `shared.payment_calculator.frequency_buttons.${selected?.value}`
        )}
      </PaymentDisplayFrequencyText>
      <Box className={cn('flex', 'justify-between')}>
        <Box className={cn('flex', 'flex-col', 'justify-between')}>
          <Typography variant="subtitle2" className="font-bold pb-2 uppercase">
            {translate('shared.payment_calculator.number_of_payments')}
          </Typography>
          <NumberOfPayments className={cn('headingL', 'text-SnapBlue')}>
            {selected?.frequency}
          </NumberOfPayments>
        </Box>
        <Box className="flex flex-col justify-between">
          <Typography variant="subtitle2" className="font-bold pb-2 uppercase">
            {translate('shared.payment_calculator.total_payments')}
          </Typography>
          <NumberOfPayments className={cn('headingL', 'text-SnapBlue')}>
            {currencyFormatter.format(data?.totalCost)}
          </NumberOfPayments>
        </Box>
      </Box>
      <ApplyButton
        data-testid="apply-btn"
        className={cn('w-full', 'mt-8')}
        onClick={() => {
          tracking.logClickEvent({
            click_type: 'cta',
            click_value: translate('shared.apply_now') as string,
            placement: 'calculator',
          });
        }}
      >
        {translate('shared.apply_now')}
      </ApplyButton>
    </PaymentDisplayContainer>
  );
}

export default PaymentInfo;
