import { FetchDataFunctionParams } from '@one-snap/sparkles';
import { useFetchWithErrorTracking } from '@ee-monorepo/shared/utilities/hooks';
import { useCallback } from 'react';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';

export function useFetchAddressSuggestions() {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();

  const fetchAddressSuggestions = useCallback(
    ({ keyword }: FetchDataFunctionParams) => {
      if (keyword.length <= 4) {
        return Promise.resolve([]);
      } else {
        return fetchWithErrorTracking(
          appConfig.addressValidationApi + 'search/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ term: keyword, maxSuggestions: 7 }),
          }
        )
          .then((result) => result.json())
          .then((result) => result.suggestions)
          .then((result) => {
            return result.map((s: any) => {
              return { key: s.global_address_key, value: s.text };
            });
          });
      }
    },
    [fetchWithErrorTracking]
  );

  return { fetchAddressSuggestions };
}
