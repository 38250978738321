import * as yup from 'yup';

export const REQUIRED = 'shared.forms.validations.required';
export const EMAIL = 'shared.forms.validations.email';
export const EMAIL_CONFIRMATION = 'shared.forms.validations.emailConfirmation';
export const DUPLICATE_DBA_NAME = 'shared.forms.validations.duplicateDBAName';
export const TAX_ID_LENGTH = 'shared.forms.validations.taxIdLength';
export const NUMBER = 'shared.forms.validations.number';
export const PHONE_LENGTH = 'shared.forms.validations.phoneLength';
export const FORMAT_STRING_LENGTH = 'shared.forms.validations.numberLength';
export const DIGITS_ONLY = 'shared.forms.validations.digitsOnly';
export const EIN_NUMBER = 'shared.forms.validations.einNumber';
export const STREET_ADDRESS = 'shared.forms.validations.streetAddress';
export const ZIP_CODE = 'shared.forms.validations.zipCode';
export const PERSON_OLD_ENOUGH = 'shared.forms.validations.personOldEnough';
export const WEBSITE_ADDRESS = 'shared.forms.validations.invalidWebsiteAddress';
export const CLEAR_PARAMS = 'shared.forms.validations.invalidClearParams';

declare module 'yup' {
  interface StringSchema {
    clearParams(): StringSchema;
    digitsOnly(): StringSchema;
    einNumberValidation(): StringSchema;
    phoneNumberValidation(): StringSchema;
    digitsOnlyWithDash(): StringSchema;
    websiteAddressValidation(): StringSchema;
  }
}

yup.addMethod<yup.StringSchema>(yup.string, 'clearParams', function () {
  return this.matches(/^[A-Za-z0-9\s\-.,'&#]+$/, CLEAR_PARAMS);
});

yup.addMethod<yup.StringSchema>(yup.string, 'digitsOnly', function () {
  return this.matches(/^\d+$/, DIGITS_ONLY);
});

yup.addMethod<yup.StringSchema>(yup.string, 'digitsOnlyWithDash', function () {
  return this.matches(/^[0-9-]+$/, DIGITS_ONLY);
});

yup.addMethod<yup.StringSchema>(
  yup.string,
  'phoneNumberValidation',
  function () {
    return this.matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, PHONE_LENGTH);
  }
);

yup.addMethod<yup.StringSchema>(yup.string, 'einNumberValidation', function () {
  return this.matches(/^\d{2}-\d{7}$/, EIN_NUMBER);
});

yup.addMethod<yup.StringSchema>(
  yup.string,
  'websiteAddressValidation',
  function () {
    return this.matches(
      /^(https?:\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,6}(?:\.[a-z]{2,6})*){1,3}(\/[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9_]+=[a-zA-Z0-9-%]+&?)?$/,
      WEBSITE_ADDRESS
    );
  }
);

export interface FormComponentProps<T> {
  onValid?: (data?: T) => void;
  onInvalid?: (data: T) => void;
  onSubmit?: (data: T) => void;
  onChange?: (data: T) => void;
  nextStep?: (data: T) => void;
  prevStep?: (data: T) => void;
  formData?: T;
}

// Define the handle types which will be passed to the forwardRef
export interface SubmitDataHandle {
  submitData: () => void;
}
