import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { useCall } from '@ee-monorepo/shared/utilities/hooks';
import { useFetchWithErrorTracking } from '@ee-monorepo/shared/utilities/hooks';

export type MerchantApplicationStatus =
  | 'PENDING_APPLICATION'
  | 'DENIED'
  | 'DEFER'
  | 'ONBOARDING';

export interface MerchantApplicationData {
  status: MerchantApplicationStatus;
  id: number;
  uuid: string;
  productTypes?: { [key: string]: boolean };
  discount?: { [key: string]: number };
  /* applicant step fields */
  applicantFirstName: string;
  applicantLastName: string;
  applicantEmail: string;
  applicantMobileNumber: string;

  /* business step fields */
  dbaName: string;
  legalName: string;
  taxId: string;
  duns: string;
  state: string;
  industry: string;
  numberOfOwners: number;
  website: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerEmail: string;
  ownerMobileNumber: string;
  ownerBirthDt: string;

  /* store information step fields */
  locationsCnt: string | number;
  streetAddress: string;
  unit: string;
  city: string;
  zipCode: string;
  storePhoneNumber: string;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  primaryContactMobileNumber: string;
  primaryContactEmail: string;
}

export function useMerchantApplication() {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();
  const {
    call: submitInitialMerchantApplication,
    loading: submittingInitialMerchantApplication,
    data: initialMerchantApplicationData,
    error: initialMerchantApplicationError,
  } = useCall<MerchantApplicationData>((data: MerchantApplicationData) =>
    fetchWithErrorTracking(
      `${appConfig.merchantOnboardingEndpoint}/merchant-application`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
  );

  const {
    call: getMerchantApplication,
    data: merchantApplicationData,
    loading: merchantApplicationDataLoading,
  } = useCall<MerchantApplicationData>((mid: string) =>
    fetchWithErrorTracking(
      `${appConfig.merchantOnboardingEndpoint}/merchant-application/${mid}`
    )
  );

  const {
    call: submitMerchantApplication,
    loading: submitMerchantApplicationLoading,
    data: submitResponseData,
    error: submitMerchantApplicationError,
  } = useCall<{
    merchantLeadId: string;
    recommendation: MerchantApplicationStatus;
    decisioningModel: string;
  }>((mid: string, data: MerchantApplicationData) =>
    fetchWithErrorTracking(
      `${appConfig.merchantOnboardingEndpoint}/merchant-application/${mid}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(data),
      }
    )
  );

  const {
    call: verifyMerchantAgreement,
    data: verifyMerchantAgreementData,
    loading: verifyMerchantAgreementLoading,
    error: verifyMerchantAgreementError,
  } = useCall<{ urlAgreement: string }>((token: string, urlAgreement: string) =>
    fetchWithErrorTracking(
      `${appConfig.snapAppApi}/merchant-application/merchantAgreementVerify`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, urlAgreement }),
      }
    )
  );

  const {
    call: createMerchant,
    data: createMerchantData,
    loading: createMerchantLoading,
    error: createMerchantError,
  } = useCall<{ registrationLink: string }>((token: string) =>
    fetchWithErrorTracking(
      `${appConfig.merchantOnboardingEndpoint}/merchant-application/create-merchant`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      }
    )
  );

  return {
    submitInitialMerchantApplication,
    submitMerchantApplicationError,
    submitMerchantApplicationLoading,
    submitResponseData,
    submitMerchantApplication,
    getMerchantApplication,
    merchantApplicationData:
      merchantApplicationData || initialMerchantApplicationData,
    loading:
      merchantApplicationDataLoading ||
      submittingInitialMerchantApplication ||
      submitMerchantApplicationLoading,
    merchantApplicationError: initialMerchantApplicationError,
    createMerchant,
    createMerchantData,
    createMerchantLoading,
    createMerchantError,
    verifyMerchantAgreement,
    verifyMerchantAgreementData,
    verifyMerchantAgreementLoading,
    verifyMerchantAgreementError,
  };
}
